import Link from 'next/link'
import ArrowRight from '@/assets/img/conciergerie/arrow-right.svg'
import styles from './ChristmasBanner.module.css'
import clsx from 'clsx'

const GIFT_PATH = '/gift'

export default function ChristmasBanner({ children, path = '/' }) {
  return (
    <Link href={GIFT_PATH} passHref>
      <div className="container mx-auto flex pt-20">
        <section
          className={clsx(
            'flex mx-auto z-10 pl-[37px] pr-15 py-5 text-white items-center',
            path === '/' && styles.banner,
            path !== '/' && styles.bannerGift
          )}
        >
          <div className="relative w-[1px] h-[28px]">
            <div
              className="absolute -top-[9px] -left-[35px] text-[33px] -rotate-[22deg] box-shadow-lg"
              style={{
                textShadow: '4px 8px 5px rgba(0, 0, 0, 0.25)',
              }}
            >
              🎄
            </div>
          </div>
          <div className="flex-grow py-5 text-center text-12 md:text-14 font-bold">
            {children}
          </div>
          {path != GIFT_PATH && (
            <>
              <div className="py-5 px-10">
                <div className="h-[17px] w-[2px] bg-white/20 border-l border-noir/20 border-l-1"></div>
              </div>
              <div className="py-5">
                <ArrowRight />
              </div>
            </>
          )}
        </section>
      </div>
    </Link>
  )
}
