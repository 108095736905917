import { useRef, useState, forwardRef, cloneElement } from 'react'
import { useId } from '@react-aria/utils'
import CircleCheck from '@/assets/svg/circle-check.svg'
import styles from './index.module.css'
import clsx from 'clsx'

const Input = ({
  label,
  labelProps,
  labelClassName,
  className,
  hint,
  onFocus,
  onBlur,
  value,
  onClick,
  error,
  isValid,
  forwardedRef,
  noBorder,
  noShadow,
  noBackground,
  inputClassName = 'text-primary',
  suffix,
  type,
  isGroup,
  isLast,
  isFirst,
  isTextarea,
  unit = null,
  'aria-invalid': ariaInvalid,
  'aria-describedby': ariaDescribedby,
  ...props
}) => {
  const id = useId(props.id)
  const errorId = useId(`error-${id}`)
  const inputRef = useRef()
  const [isFocused, setIsFocused] = useState(false)

  return (
    <>
      <div
        ref={forwardedRef}
        className={`relative flex flex-col ${
          isFocused
            ? 'ring-2 ring-primary z-10'
            : error
            ? 'ring-2 ring-danger'
            : noBorder
            ? ''
            : 'ring-1 ring-dark-20'
        } ${noShadow ? '' : 'shadow'} ${className || ''} ${
          isGroup
            ? isFirst
              ? 'rounded-l-5 rounded-r-none'
              : isLast
              ? 'rounded-r-5 rounded-l-none'
              : ''
            : 'rounded-5'
        } ${isTextarea ? 'px-15 py-10 h-120' : 'px-15 h-60'} ${
          isTextarea && label ? 'pt-25' : ''
        } ${isTextarea && props.maxLength ? 'pb-15' : ''} ${
          props.disabled ? '' : 'hover:cursor-text'
        } ${noBackground ? '' : props.disabled ? 'bg-dark-10/5' : 'bg-white'}`}
        onClick={() => {
          if (inputRef.current) {
            inputRef.current.focus()
          }

          if (onClick) {
            onClick()
          }
        }}
      >
        <label
          {...labelProps}
          htmlFor={id}
          className={clsx(
            'absolute left-15 transform motion-safe:transition-all motion-safe:duration-100',
            isTextarea
              ? !value && !isFocused
                ? ''
                : 'text-12 top-10'
              : !value && !isFocused
              ? ' top-1/2 -translate-y-1/2'
              : 'text-12 top-15',
            'text-dark-60 leading-none pointer-events-none',
            !value && !isFocused ? labelClassName : ''
          )}
        >
          {label}
        </label>
        <div className="flex grow items-center justify-between gap-10">
          {isTextarea ? (
            <>
              <textarea
                {...props}
                ref={inputRef}
                id={id}
                name={id}
                value={value}
                className={`bg-transparent font-bold focus:outline-none grow resize-none ${
                  !value && !isFocused ? 'h-0 opacity-0' : 'h-full'
                } ${inputClassName}`}
                aria-invalid={error ? 'true' : undefined}
                aria-describedby={error ? errorId : undefined}
                onFocus={(e) => {
                  setIsFocused(true)

                  if (onFocus) {
                    onFocus(e)
                  }
                }}
                onBlur={(e) => {
                  setIsFocused(false)

                  if (onBlur) {
                    onBlur(e)
                  }
                }}
              ></textarea>
              {props.maxLength && (
                <p className="absolute bottom-5 right-10 text-12 text-dark-40">
                  {value.length} / {props.maxLength}
                </p>
              )}
            </>
          ) : (
            <input
              {...props}
              type={type}
              ref={inputRef}
              id={id}
              name={id}
              value={!unit || isFocused ? value : value + unit}
              className={`max-w-full bg-transparent font-bold focus:outline-none flex-grow truncate ${
                !value && !isFocused ? 'h-0 opacity-0' : ''
              } ${
                type === 'number' ? styles.typeNumber : ''
              } ${inputClassName} ${label ? 'pt-15' : ''}`}
              aria-invalid={ariaInvalid || (error ? 'true' : undefined)}
              aria-describedby={
                ariaDescribedby || (error ? errorId : undefined)
              }
              onFocus={(e) => {
                setIsFocused(true)

                if (onFocus) {
                  onFocus(e)
                }
              }}
              onBlur={(e) => {
                setIsFocused(false)

                if (onBlur) {
                  onBlur(e)
                }
              }}
            />
          )}
          {suffix
            ? cloneElement(suffix, {
                className: 'absolute bottom-10 right-10',
              })
            : null}
          {isValid && (
            <div className="absolute top-1/2 -translate-y-1/2 right-15 text-primary w-15">
              <CircleCheck width={15} height={15} />
            </div>
          )}
        </div>
      </div>
      {hint ? <p className="text-12 text-dark-40 mt-10">{hint}</p> : null}
      {error ? (
        <p className="text-danger text-14 mt-5" id={errorId}>
          {error}
        </p>
      ) : null}
    </>
  )
}

export default forwardRef(function WrappedInput(props, ref) {
  return <Input {...props} forwardedRef={ref} />
})
