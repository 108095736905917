'use client'
import Image from 'next/image'
import CarIcon from './icons/car.svg'
import TaxiIcon from './icons/taxi.svg'
import EsimIcon from './icons/esim.svg'
import VisaIcon from '@/assets/svg/trip/visa.svg'

import Link from 'next/link'

const ITEMS = [
  {
    title: 'Location de voiture',
    description: 'La même qualité de service, pour louer vos voitures.',
    icon: <CarIcon className="fill-current group-hover:text-blue" />,
    url: '/cars',
  },
  {
    title: 'Transfert aéroport',
    description: 'Laissez-vous conduire et ne vous souciez plus de rien. ',
    icon: <TaxiIcon className="fill-current group-hover:text-blue" />,
    url: 'https://www.book-online-transfers.com/fr/ulysse-airport-taxi',
  },
  {
    title: 'Visa',
    description: 'Vérifiez les conditions de voyage et de visa.',
    icon: <VisaIcon className="fill-current group-hover:text-blue" />,
    url: '/visas',
  },
  {
    title: 'Internet à l’étranger (eSim)',
    description: 'Restez connecté partout où vous allez.',
    icon: <EsimIcon className="fill-current group-hover:text-blue" />,
    url: 'https://holafly.sjv.io/o4qAxe',
  },
]

function OrganiseTrip() {
  return (
    <div className="inline-flex shadow-menu rounded-[10px] w-[720px] bg-white overflow-hidden z-20 relative">
      <div className="flex flex-col w-[300px] border-r border-[#F7F7F7]">
        <div className="relative w-full h-[130px]">
          <Image
            src="/img/components/nav/cover.png"
            alt="Organise Trip"
            fill
            style={{ objectFit: 'cover' }}
          />
        </div>
        <div className="p-[30px]">
          <h3 className="text-[22px] font-bold mb-10 leading-none">
            Organiser
            <br /> mon voyage
          </h3>
          <p className="font-normal text-dark/50 text-[15px]">
            Les détails qui font toute la différence.
          </p>
        </div>
      </div>
      <div className="p-20">
        {ITEMS.map((item, index) => (
          <Link
            className="flex gap-15 p-10 group hover:bg-blue/10 transition-all duration-[100ms] rounded-5 outline-none"
            key={index}
            href={item.url}
          >
            <div>
              <div className="w-40 h-40 rounded-5 bg-bg group-hover:bg-white relative flex items-center justify-center">
                {item.icon}
              </div>
            </div>
            <div className="flex flex-col">
              <h3 className="font-normal text-[15px] group-hover:text-blue">
                {item.title}
              </h3>
              <p className="font-normal text-dark/50 text-[12px]">
                {item.description}
              </p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  )
}

export default OrganiseTrip
